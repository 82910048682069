import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Box, Flex, Text, Button,
} from 'theme-ui';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as AccountIcon } from '../assets/account.svg';
import { ReactComponent as DeleteIcon } from '../assets/delete.svg';
import { ReactComponent as UserIcon } from '../assets/user.svg';
import { ReactComponent as Marker } from '../assets/marker.svg';
import ProfileImage from './ProfileImage';
import theme from '../theme';
import Loader from './Loader';
import useClickOutside from '../hooks/useClickOutside';

const NavItem = ({
  url, location, children,
}) => {
  const path = location?.pathname;

  return (
    <Flex
      sx={{
        position: 'relative',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '32px 0',
      }}
      px={2}
      role="menuitem"
      tabIndex="-1"
    >
      {path === url && (
        <Box sx={{ position: 'absolute', right: 0, color: 'navy' }}>
          <Marker role="img" aria-hidden="true" title="selected" alt="selected-marker" width={14} height={36} />
        </Box>
      )}
      <Link
        to={url}
        style={{
          textAlign: 'center',
          textDecoration: 'none',
          color: path === url ? theme.colors.orangeLight : theme.colors.lightGray,
        }}
        aria-current={path === url ? 'page' : ''}
      >
        {children}
      </Link>
    </Flex>
  );
};

function UserInfo({
  currentUser, handleLogout, setIsOpen, accountId, isLoggingOut, setIsLoggingOut,
}) {
  const userInfoRef = useRef();

  const handleClickOutside = (e) => {
    // If the the click occurs outside of the UserInfo container
    if (!userInfoRef.current.contains(e.target)) {
      // Set the focus to false
      setIsOpen(false);
    }
  };

  useClickOutside(handleClickOutside);

  return (
    <Box
      data-testid="user-info"
      ref={userInfoRef}
      p={4}
      sx={{
        width: 320,
        position: 'absolute',
        bottom: theme.space[7],
        left: 110,
        zIndex: 1,
        display: 'grid',
        alignItems: 'center',
        gridTemplateRows: '1fr auto',
        gridTemplateAreas: '"info" "controls"',
        backgroundColor: 'lighterNavy',
        borderRadius: 5,
        border: '1px solid rgba(128, 138, 168, 0.5)',
        boxShadow: '3px 0px 6px rgba(0, 0, 0, 0.356643)',
      }}
    >
      <Flex
        sx={{
          cursor: 'pointer',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          right: 0,
          width: '40px',
          height: '40px',
          color: 'grayLighter',
        }}
        data-testid="close-user-profile"
        onClick={() => setIsOpen(false)}
        onKeyDown={({ key }) => { if (key === 'Enter') { setIsOpen(false); } }}
      >
        <DeleteIcon
          width={22}
          height={22}
          aria-label="close"
          style={{ padding: '5px', color: 'inherit' }}
        />
      </Flex>
      <Box
        px={6}
        py={5}
        sx={{
          gridArea: 'info',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box mb={5}>
          <ProfileImage pictureUrl={currentUser?.pictureUrl} size={96} />
        </Box>
        <Text mb={1} sx={{ color: 'white', fontSize: 2 }}>{currentUser?.loginName}</Text>
        <Text sx={{ color: 'white', fontSize: 0 }}>{`Account: ${accountId}`}</Text>
      </Box>
      <Button
        variant="outlinedWhite"
        onClick={() => {
          setIsLoggingOut(true);
          handleLogout('You have been logged out');
        }}
        style={{ height: 40, cusor: isLoggingOut ? 'default' : 'pointer' }}
      >
        {isLoggingOut ? <Loader size={20} /> : 'Logout'}
      </Button>
    </Box>
  );
}

function Nav({
  handleLogout, currentUser, accountId, location,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const toggleOpen = () => setIsOpen((o) => !o);

  return (
    <Flex
      pt={3}
      pb={7}
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        gridArea: 'nav',
        background: 'linear-gradient(180deg, #1D1F52 0%, #151748 100%)',
        overflow: 'auto',
        color: 'white',
        justifyContent: 'space-between',
      }}
      role="banner"
    >
      <Flex mb={3} sx={{ flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        <Box pt={2} sx={{ color: 'orange', marginBottom: 5 }}>
          <Logo width={30} height={30} role="img" aria-label="liveperson logo" />
        </Box>
        <Box role="navigation" aria-label="main">
          <Box role="menubar" aria-label="main">
            <NavItem url="/accounts" location={location} id="account-overview">
              <AccountIcon width={28} height={28} role="img" aria-hidden="true" />
              <Box variant="navTitle" id="account-overview">Account Overview</Box>
            </NavItem>
            <NavItem url="/users" location={location} id="user-overview">
              <UserIcon width={28} height={28} role="img" aria-hidden="true" />
              <Box variant="navTitle" id="User-overview">User Overview</Box>
            </NavItem>
          </Box>
        </Box>
      </Flex>
      {currentUser !== null && (
        <Box
          role="button"
          title="Toggle info"
          onClick={toggleOpen}
          onKeyDown={({ key }) => {
            if (key === 'Enter') { toggleOpen(); }
            if (key === 'Escape') { setIsOpen(false); }
          }}
          tabIndex="0"
          aria-label="user profile"
        >
          <ProfileImage pictureUrl={currentUser?.pictureUrl} hasPointer size={48} />
        </Box>
      )}
      {currentUser && isOpen && (
        <UserInfo
          currentUser={currentUser}
          handleLogout={handleLogout}
          setIsOpen={setIsOpen}
          isLoggingOut={isLoggingOut}
          setIsLoggingOut={setIsLoggingOut}
          accountId={accountId}
        />
      )}
    </Flex>
  );
}

Nav.whyDidYouRender = true;

export default Nav;
