/* eslint-disable no-console */
/* eslint-disable camelcase */
// NOTE: This is due to the provided token properties not being in camelcase
import React, {
  useRef, useState, useEffect,
} from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { Box, Grid } from 'theme-ui';
import IdleTimer from 'react-idle-timer';
import axios from 'axios';
import { format } from 'fecha';
import Account from './Account';
import User from './User';
import Login from './Login';
import Nav from './Nav';
import useLocalStorage from '../hooks/useLocalStorage';
import AnalyticsContext from './AnalyticsContext';
import {
  NETWORK_TIMEOUT, INVALID_PERMISSIONS, REFRESH_TIMEOUT, SERVICE_NAME, APP_NAME,
  ANALYTICS_DEBUG_ENABLED,
} from '../consts';

const jwt = require('jsonwebtoken');

// Routes to show to the user when they are NOT authenticated
function UnAuthenticatedRoutes({
  token, setToken, accountId, setAccountId, logoutReason,
}) {
  return (
    <Switch>
      <Route path="/login" exact>
        <Login
          token={token}
          setToken={setToken}
          accountId={accountId}
          setAccountId={setAccountId}
          logoutReason={logoutReason}
        />
      </Route>
      <Redirect to="/login" />
    </Switch>
  );
}

// Routes to show to the user when they ARE authenticated
function AuthenticatedRoutes({
  accountId, token, logoutOnError, setCurrentUser, currentUser,
}) {
  return (
    <Switch>
      <Route path="/users" exact>
        <User
          accountId={accountId}
          token={token}
          logoutOnError={logoutOnError}
          setCurrentUser={setCurrentUser}
          currentUser={currentUser}
        />
      </Route>
      <Route path="/accounts" exact>
        <Account
          accountId={accountId}
          token={token}
          logoutOnError={logoutOnError}
          currentUser={currentUser}
        />
      </Route>
      <Redirect to="/accounts" />
    </Switch>
  );
}

function App() {
  const [token, setToken] = useLocalStorage('token', null);
  const [logoutReason, setLogoutReason] = useState(null);
  const [accountId, setAccountId] = useLocalStorage('accountId', '');
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const idleTimer = useRef(null);
  const location = useLocation();

  async function sendEvent({
    eventName, context, value, term,
  }) {
    // Create the payload using the README instructions at https://lpgithub.dev.lprnd.net/WebJedi/lp-loggos-app
    const body = {
      logLevel: 'bam',
      context,
      eventName,
      serviceName: SERVICE_NAME,
      appName: APP_NAME,
      loginName: currentUser?.loginName,
      userId: `${currentUser?.id}`,
      value,
      term,
      url: window.location.href,
      clientTime: format(new Date(), 'YYYY-MM-DD hh:mm:ss,SSS'),
    };

    // Log event if debug is enabled
    if (ANALYTICS_DEBUG_ENABLED) {
      if (value) {
        // Use fancy colors to style the output in the browser console
        // eslint-disable-next-line no-console
        console.log(
          `%c${context} metric: %c${eventName} %c(${value})`,
          'color: #A7ACBE;', 'color: #8BA3EA;', 'color: #005EF4;',
        );
      } else {
        // eslint-disable-next-line no-console
        console.log(
          `%c${context} metric: %c${eventName}`,
          'color: #A7ACBE;', 'color: #8BA3EA;',
        );
      }
    }

    // Post BAM event to Loggos service
    // Event will only fire if the call to fetch baseURI did not fail
    if (accountId && token) {
      try {
        await axios.post(
          '/api/analytics/bam',
          {
            body,
            accountId,
            token: token.access_token,
          },
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e?.message);
      }
    }
  }

  const handleLogout = async (reason) => {
    axios.post(
      '/api/auth/logout',
      { accountId, reason, token },
      { timeout: NETWORK_TIMEOUT },
    ).then(() => {
      setLogoutReason(reason);
      setToken(null);
      sendEvent({ context: 'Login', eventName: `Logout from ${reason} success` });
    }).catch((e) => {
      console.warn(`Logout reason: ${reason} / ${e.message}`);
      setLogoutReason(reason);
      setToken(null);
      sendEvent({ context: 'Login', eventName: `Logout from ${reason} failure` });
    });
  };

  const logoutOnError = (e) => {
    if (e?.response?.status === 401) {
      handleLogout('invalid token');
    } else {
      handleLogout(e?.message);
    }
  };

  const handleUserData = (response) => {
    // logout users with invalid permissions
    if (response?.data?.message?.includes(INVALID_PERMISSIONS)) {
      setCurrentUser(false);
      logoutOnError({ message: INVALID_PERMISSIONS });
    } else if (currentUser?.id !== response?.data?.id) {
      // No need to set the currentUser if it is already set (from localstorage)
      setCurrentUser(response?.data);
    }
  };

  const getUser = async () => {
    // Extract values from token
    const {
      is_lpa, email, username: loginName, sub: userId,
    } = jwt.decode(token.id_token);

    if (is_lpa) {
      setCurrentUser({
        id: '0', is_lpa, email, loginName, userId,
      });
    } else {
      const url = `/api/user?accountId=${accountId}&userId=${userId}`;
      await axios(url, {
        timeout: NETWORK_TIMEOUT,
        headers: {
          Authorization: token.access_token,
        },
      }).then(handleUserData).catch((e) => {
        logoutOnError(e);
      });
    }
  };

  /* istanbul ignore next */
  const refreshToken = () => {
    axios.post('/api/auth/refresh', { accountId, token }, {
      timeout: NETWORK_TIMEOUT,
    }).then(({ data }) => {
      // Merge new token data with old token
      setToken({ ...data, id_token: token.id_token });
      sendEvent({ context: 'Login', eventName: 'Login refresh success' });
    }).catch(() => {
      // Clear the token on error
      setToken(null);
      sendEvent({ context: 'Login', eventName: 'Login refesh failure' });
    });
  };

  // Send page view anytime the location changes
  useEffect(() => {
    // Analytics will only fire if they are ready, and the call to fetch baseURI did not fail
    if (ANALYTICS_DEBUG_ENABLED) {
      sendEvent({
        context: 'Navigation', eventName: 'Page changed', value: location.pathname, token,
      });
    }
  }, [location]);

  useEffect(() => {
    let refreshTimer;

    if (token && accountId) {
      // Get user info
      getUser();

      // Start a timer to refresh the authentication token
      refreshTimer = setInterval(refreshToken, REFRESH_TIMEOUT);
    } else {
      // Clear the timer if the user is not logged in
      clearInterval(refreshTimer);
    }

    // Clear the timer on component unmount
    return () => clearInterval(refreshTimer);
  }, [token, accountId]);

  const isLoggedIn = token && accountId;

  return (
    <AnalyticsContext.Provider value={{ sendEvent }}>
      {
        // NOTE: Ignore this because idle timer is difficult in a unit test
        /* istanbul ignore next */
        isLoggedIn && (
          <IdleTimer
            ref={idleTimer}
            timeout={1000 * 60 * 30}
            onIdle={() => handleLogout('idle timer')}
            debounce={250}
          />
        )
      }
      <Grid sx={{
        height: '100%',
        gridTemplateAreas: isLoggedIn ? '"nav content"' : '"content"',
        gridTemplateColumns: isLoggedIn ? '80px auto' : 'auto',
        backgroundColor: 'navy',
        gridGap: 0,
      }}
      >
        {isLoggedIn && (
          <Nav
            location={location}
            accountId={accountId}
            handleLogout={handleLogout}
            currentUser={currentUser}
          />
        )}
        <Box sx={{ gridArea: 'content', overflow: 'auto' }}>
          {isLoggedIn ? (
            <AuthenticatedRoutes
              accountId={accountId}
              token={token}
              logoutOnError={logoutOnError}
              setCurrentUser={setCurrentUser}
              currentUser={currentUser}
            />
          ) : (
            <UnAuthenticatedRoutes
              token={token}
              setToken={setToken}
              accountId={accountId}
              setAccountId={setAccountId}
              logoutReason={logoutReason}
            />
          )}
        </Box>
      </Grid>
    </AnalyticsContext.Provider>
  );
}

export default App;
