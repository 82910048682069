module.exports = {
  UNAUTHORIZED: 'Unauthorized',
  NOT_A_PARTNER: 'Not a partner',
  INVALID_REQUEST: 'Invalid Request',
  INVALID_PERMISSIONS: 'Insufficient Permissions',
  NETWORK_ERROR: 'Network Error',
  NETWORK_TIMEOUT: 30 * 1000, // 30 seconds
  REFRESH_TIMEOUT: 5 * 60 * 1000, // 5 minutes
  LOADING_SVG: 'loading-spin.svg',
  SERVICE_NAME: 'lp-partners-portal',
  APP_NAME: 'lp-partnersportal-web',
  USER_TIMEOUT: 100000,
  ANALYTICS_DEBUG_ENABLED: true,
};
