import React, {
  useEffect, useState, memo, useRef, useContext,
} from 'react';
import {
  Heading, Flex, Grid, Text,
} from 'theme-ui';
import Fuse from 'fuse.js';
import axios from 'axios';
import AccountFilter from './AccountFilter';
import Table from './Table';
import Search from './Search';
import {
  filterByType, filterByItem, sortByName, checkTokenForLPA,
} from '../utils';
import AnalyticsContext from './AnalyticsContext';
import { NETWORK_TIMEOUT } from '../consts';

const TABLE_HEADERS = [
  { gridArea: 'hd-star', name: '' },
  { gridArea: 'hd-name', name: 'Account Name' },
  { gridArea: 'hd-number', name: 'Account Number' },
  { gridArea: 'hd-region', name: 'Region' },
  { gridArea: 'hd-usage', name: 'Credit Usage' },
  { gridArea: 'hd-date', name: 'Date Created' },
  { gridArea: 'hd-dropdown', name: '' },
];

const initialiseFilter = (item) => ({ name: item, checked: false });

function Account({
  accountId, logoutOnError, token, currentUser,
}) {
  const [preferences, setPreferences] = useState({});
  const [accountData, setAccountData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { sendEvent } = useContext(AnalyticsContext);
  const [search, setSearch] = useState('');
  const [activeFilter, setActiveFilter] = useState('all');
  const [regionFilters, setRegionFilters] = useState([]);
  const accountRef = useRef(false);
  const starredAccounts = preferences?.starredAccounts || [];
  const isLPA = checkTokenForLPA(token);

  const getPreferences = async () => {
    if (!currentUser || isLPA) return;

    try {
      const { data } = await axios.get(
        `/api/merchant/preferences?accountId=${accountId}&userId=${currentUser?.id}`,
        {
          timeout: NETWORK_TIMEOUT,
          headers: {
            Authorization: token?.access_token,
          },
        },
      );

      if (data && accountRef.current) {
        setPreferences(data);
        sendEvent({
          context: 'Accounts',
          eventName: 'Get user preferences success',
          value: currentUser?.id,
        });
      }
    } catch (e) {
      if (accountRef.current) {
        // Set empty preferences in case of temporary network issue
        setPreferences({ starredAccounts: [] });
        sendEvent({
          context: 'Accounts',
          eventName: 'Get user preferences failed',
          value: currentUser?.id,
        });
      }
    }
  };

  const updateStarState = (id, starred) => {
    if (starred) {
      // Convert to a set to make the items unique
      setPreferences((p) => {
        const newAccounts = [...p.starredAccounts];
        newAccounts.push(id);
        return { starredAccounts: [...new Set(newAccounts)] };
      });
    } else {
      // Filter out the account that was clicked
      setPreferences((p) => {
        const newAccounts = p.starredAccounts.filter((item) => item !== id) || [];
        return { starredAccounts: newAccounts };
      });
    }
  };

  const updateStar = async (id, starred) => {
    // Do not allow star click if preferences has not loaded yet
    if (!preferences?.starredAccounts) return;

    try {
      // Set the star state initially (if it fails, we undo it later)
      updateStarState(id, starred);
      await axios.put(
        '/api/merchant/preferences',
        {
          accountId,
          userId: `${currentUser?.id}`,
          childSiteId: [`${id}`],
          token: token?.access_token,
          starred,
        },
        { timeout: NETWORK_TIMEOUT },
      );
      sendEvent({
        context: 'Accounts',
        eventName: `Account ${starred ? '' : 'un'}starred`,
        value: id,
      });
    } catch (e) {
      // If there was a failure, we undo the state change
      if (accountRef.current) {
        updateStarState(id, !starred);
      }

      sendEvent({
        context: 'Accounts',
        eventName: 'Account starring failed',
        value: id,
      });
    }
  };

  const mergeStarData = (account) => {
    if (starredAccounts.length) {
      // Check if the starred account is in the list
      const isStarred = starredAccounts.reduce((a, b) => a || `${b}` === account.merchantId, false);
      return ({ ...account, isStarred });
    }
    return account;
  };

  const handleAccountsData = ({ data }) => {
    if (accountRef.current) {
      // Set default value in case the API does not return the right value
      const organizationData = data?.organization || [];

      // Set the account data
      setAccountData(organizationData);

      // Initialise the filters from the regions
      setRegionFilters(filterByItem(organizationData, 'region').map(initialiseFilter).sort(sortByName));
      sendEvent({ context: 'Accounts', eventName: 'Accounts loaded', value: organizationData.length });
    }
  };

  const handleAccountsError = (e) => {
    if (accountRef.current) {
      setAccountData([]);
      if (e.message.includes('timeout of')) {
        setErrorMessage('A network timeout occurred');
        sendEvent({ context: 'Accounts', eventName: 'Accounts timed out' });
      } else {
        sendEvent({ context: 'Accounts', eventName: 'Accounts not loaded' });
      }
      logoutOnError(e);
    }
  };

  const fetchAccountData = () => {
    const accountsUrl = `/api/accounts?accountId=${accountId}`;
    if (token) {
      axios(
        accountsUrl,
        {
          timeout: NETWORK_TIMEOUT,
          headers: {
            Authorization: token.access_token,
          },
        },
      ).then(handleAccountsData).catch(handleAccountsError);
    }
  };

  // Fetch account data on initial render
  useEffect(fetchAccountData, [accountId, token]);

  // Fetch merchant user preferences
  useEffect(getPreferences, [currentUser]);

  useEffect(() => {
    // Use a ref to check if the the component is mounted
    accountRef.current = true;
    return () => { accountRef.current = false; };
  });

  const typeFilter = (item) => filterByType(activeFilter, item);
  const accountWithStarredData = accountData?.map(mergeStarData) || [];
  const filteredData = accountWithStarredData.filter(typeFilter);

  // Use Fuse.js to fuzzy search the filtered data
  const fuse = new Fuse(filteredData, {
    keys: ['name', 'region', 'merchantId'],
    findAllMatches: true,
    threshold: 0.3,
  });

  const data = search?.length ? fuse.search(search).map((d) => d.item) : filteredData;

  return (
    <Grid
      px={4}
      pt={4}
      sx={{
        height: '100%', gridTemplateRows: 'auto auto 1fr', gridGap: 0, position: 'relative',
      }}
      role="main"
      id="main-content"
    >
      <Flex mb={6} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Heading as="h1" ml={4} id="account-overview-title">Account Overview</Heading>
        <Search
          searchType="Account"
          data={filteredData}
          search={search}
          setSearch={setSearch}
          searchKeys={['name', 'region', 'merchantId']}
          placeholder="Search accounts"
        />
      </Flex>
      <Text sx={{
        color: 'white', fontSize: 0, textAlign: 'right', padding: '10px',
      }}
      >
        Sign in to merchant account for first time can take up to 2 mins.
      </Text>
      <Grid sx={{
        gridTemplateAreas: '"filter table"',
        gridTemplateColumns: '210px auto',
        gridGap: 0,
        overflow: 'hidden',
        borderRadius: '8px 8px 0 0',
      }}
      >
        <AccountFilter
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          regionFilters={regionFilters}
          setRegionFilters={setRegionFilters}
          accountData={accountWithStarredData}
          filteredData={filteredData}
        />
        <Table
          data={data}
          activeFilter={activeFilter}
          search={search}
          columns="50px 4fr 2fr 2fr 2fr 2fr 3fr"
          tableHeaders={TABLE_HEADERS}
          errorMessage={errorMessage}
          updateStar={updateStar}
          preferences={preferences}
          isLoading={accountData === null}
          isLPA={isLPA}
          regionFilters={regionFilters}
          accountId={accountId}
        />
      </Grid>
    </Grid>
  );
}

Account.whyDidYouRender = true;

export default memo(Account);
