import { useEffect } from 'react';

export default function useClickOutside(callback) {
  useEffect(() => {
    // Add an event listener to detect clicks that happen outside the search container
    document.addEventListener('mousedown', callback);
    // return function to remove the event listener when the component unmounts
    return () => document.removeEventListener('mousedown', callback);
  }, []);
}
