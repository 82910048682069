import React, { memo, useContext, useState } from 'react';
import {
  Box, Flex, Grid, Text,
} from 'theme-ui';
import { format } from 'fecha';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { ReactComponent as Star } from '../assets/star.svg';
import { ReactComponent as StarActive } from '../assets/star-active.svg';
import { ReactComponent as SignIn } from '../assets/sign-in.svg';
import AnalyticsContext from './AnalyticsContext';
import Loader from './Loader';
import { NETWORK_TIMEOUT, USER_TIMEOUT } from '../consts';

async function fetchBaseURI(accountId) {
  const { data } = await axios.post(
    '/api/baseuri',
    { service: 'liveEngage', accountId },
    { timeout: NETWORK_TIMEOUT },
  );
  return (data.baseURI);
}

function AccountRow({
  account, columns, gap, updateStar, isLPA, accountId,
}) {
  const {
    isStarred, name, region, createdDate, creditUsage, merchantId,
  } = account;

  const [isLoading, setIsLoading] = useState(false);
  const { sendEvent } = useContext(AnalyticsContext);

  const StarIcon = isStarred ? StarActive : Star;
  const formattedDate = format(new Date(createdDate), 'MMM D, YYYY');

  const handleAuthMerchantLoginError = (e, merchantAccount) => {
    if (e.message.includes('timeout of')) {
      sendEvent({ context: 'Account', eventName: 'Merchant login timed out', value: merchantAccount });
    } else {
      sendEvent({ context: 'Account', eventName: 'Merchant login not loaded', value: merchantAccount });
    }
  };

  const handleAuthMerchantLogin = async (res, merchantAccount) => {
    try {
      // Validate cookie
      const baseURI = await fetchBaseURI(accountId);

      let token = res.data['set-cookie'][0].substring(res.data['set-cookie'][0].indexOf('=') + 1);
      token = token.substring(0, token.indexOf(';'));
      const cookie = decodeURI(res.data['set-cookie'][0]);
      document.cookie = cookie;

      const loginSuccess = (eventName) => {
        setIsLoading(false);
        sendEvent({ context: 'Account', eventName, value: merchantAccount });
        window.open(`https://${baseURI}/le/account/${accountId}/session/agent`, '_blank');
      };
      const { newUserCreated } = jwt.decode(token);
      if (newUserCreated) {
        setTimeout(() => loginSuccess('Merchant first time login successful'), USER_TIMEOUT);
      } else {
        loginSuccess('Merchant login successful');
      }
    } catch (e) {
      setIsLoading(false);
      sendEvent({ context: 'Account', eventName: 'No cookie', value: merchantAccount });
    }
  };

  async function authMerchantLogin(merchantAccount) {
    setIsLoading(true);
    const LoginUrl = '/api/merchant/login';
    const baseURI = await fetchBaseURI(accountId);
    await axios.post(
      LoginUrl,
      {
        merchantAccount,
        partnerAccount: accountId,
        baseURI,
      },
      {
        timeout: NETWORK_TIMEOUT,
        headers: {
          Authorization: JSON.parse(window.localStorage.token).access_token,
        },
      },
    ).then((res) => handleAuthMerchantLogin(res, merchantAccount))
      .catch((e) => handleAuthMerchantLoginError(e, merchantAccount));
  }

  return (
    <>
      <Grid
        data-testid="account-row"
        sx={{
          gridTemplateAreas: '"star name number region usage date dropdown"',
          gridTemplateColumns: columns,
          borderColor: 'otherNavy',
          borderStyle: 'solid',
          borderWidth: '0 0 1px 0',
          height: '60px',
          alignItems: 'center',
          gridGap: gap,
          paddingRight: 4,
          position: 'relative',
          color: '#8C8FAB',
        }}
        role="gridcell"
        tabIndex="0"
      >
        <Flex
          variant="rowItem"
          role="row"
          pl={5}
          sx={{ gridArea: 'star', height: '100%', alignItems: 'center' }}
          onClick={() => !isLPA && updateStar(merchantId, !isStarred)}
        >
          {
            // Hide star for LPA user
            !isLPA && (
              <StarIcon
                aria-label={isStarred ? 'active star' : 'star'}
                role="button"
                aria-pressed={!!isStarred}
                width={18}
                height={17}
                style={{ cursor: 'pointer' }}
              />
            )
          }
        </Flex>
        <Box variant="rowItem" role="row" sx={{ gridArea: 'name', lineHeight: '60px' }} title={name}>{name}</Box>
        <Box variant="rowItem" role="row" sx={{ gridArea: 'number', lineHeight: '60px' }} title={merchantId}>{merchantId}</Box>
        <Box variant="rowItemAlt" role="row" sx={{ gridArea: 'region' }} title={region}>{region}</Box>
        <Box variant="rowItemAlt" role="row" sx={{ gridArea: 'usage' }} title={creditUsage}>{creditUsage}</Box>
        <Box variant="rowItemAlt" role="row" sx={{ gridArea: 'date' }} title={formattedDate}>
          {formattedDate}
        </Box>
        {isLoading
          ? <Loader />
          : (
            <Flex
              onClick={() => { if (!isLPA) { authMerchantLogin(merchantId); } }}
              variant="rowItemAction"
              role="row"
              title={`Sign in${isLPA ? ' disabled for LPA' : ''}`}
              sx={{
                gridArea: 'dropdown',
                justifyContent: 'center',
                alignItems: 'center',
                color: isLPA ? 'midGray' : 'periwinkleLight',
                cursor: isLPA ? 'default' : 'pointer',
              }}
            >
              <Text
                aria-label="merchant sign in"
                role="button"
                sx={{
                  fontWeight: 700,
                  marginRight: 2,
                  flexShrink: 0,
                }}
              >
                Sign in
              </Text>
              <SignIn
                aria-label="sign in"
                role="button"
                aria-pressed={false}
                width={12}
                height={13}
                sx={{
                  cursor: isLPA ? 'default' : 'pointer',
                }}
              />
            </Flex>
          )}
      </Grid>
    </>
  );
}

AccountRow.whyDidYouRender = true;

export default memo(AccountRow);
