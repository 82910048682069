import React, { memo, useRef, useState } from 'react';
import { Box } from 'theme-ui';

const Checkbox = ({ checked, onChange, name }) => {
  const ref = useRef();
  const [hasFocus, setFocus] = useState(false);

  return (
    <Box sx={{ marginRight: 3, minWidth: 'min-content' }}>
      <Box
        ref={ref}
        as="input"
        type="checkbox"
        defaultChecked={checked}
        onChange={onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        sx={{
          position: 'absolute',
          opacity: 0,
          zIndex: -1,
          width: 1,
          height: 1,
          overflow: 'hidden',
        }}
        htmlFor={name}
      />
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          borderRadius: '4px',
          boxShadow: hasFocus ? '0px 0px 6px 4px rgba(255, 255, 255, 0.2)' : 'none',
          backgroundColor: hasFocus ? 'rgba(238, 114, 46, 0.3)' : 'transparent',
        }}
      >
        {checked ? <rect width="20" height="20" rx="4" fill="#EE722E" /> : <rect x="1" y="1" width="18" height="18" rx="3" stroke="#C5C7CE" strokeWidth="2" />}
        {checked ? <path d="M5 9.9375L9 13L15 6" stroke="white" strokeWidth="1.6" strokeLinecap="round" /> : null}
      </svg>
    </Box>
  );
};

Checkbox.whyDidYouRender = true;

export default memo(Checkbox);
