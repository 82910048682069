import React, { memo } from 'react';
import { Input, Label } from 'theme-ui';

function UserInput({
  item, fieldErrors, userFields, handleInputChange,
}) {
  const hasError = !!fieldErrors[item.name];

  function getVariant() {
    if (item.disabled) return 'inputDisabled';
    return hasError ? 'inputError' : 'input';
  }

  return (
    <Label htmlFor={item.name} mb={3} sx={{ display: 'block', color: item.disabled ? 'cloudDark' : 'inherit' }}>
      {`${item.label}${item.required ? ' *' : ''}`}
      <Input
        key={item.name}
        id={item.name}
        variant={getVariant()}
        value={userFields[item.name] || ''}
        onChange={(e) => handleInputChange(item.name, e.target.value)}
        placeholder={item.placeholder}
        mt={1}
        type={item.type || 'text'}
        autoComplete={`new-${item.name}`}
        aria-invalid={hasError ? 'true' : undefined}
        aria-required={!!item.required}
        disabled={item.disabled}
      />
    </Label>
  );
}

UserInput.whyDidYouRender = true;

export default memo(UserInput);
