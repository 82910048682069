import React, { memo, useContext } from 'react';
import { Box, Grid } from 'theme-ui';
import { format } from 'fecha';
import AnalyticsContext from './AnalyticsContext';

function UserRow({
  user, columns, gap, setUserInfo, setModalType, isLPA,
}) {
  const { id, dateUpdated } = user;

  const { sendEvent } = useContext(AnalyticsContext);

  const formattedDate = dateUpdated ? format(new Date(dateUpdated), 'MMM D, h:mm A') : 'N/A';

  const editUser = () => {
    // Prevent LPA users from editing users
    if (!isLPA) {
      setUserInfo(user);
      setModalType('edit');
      sendEvent({ context: 'User', eventName: 'User edit clicked', value: id });
    }
  };

  return (
    <Grid
      sx={{
        gridTemplateAreas: '"name email nickname login-name last-updated"',
        gridTemplateColumns: columns,
        borderColor: 'otherNavy',
        borderStyle: 'solid',
        borderWidth: '0 0 1px 0',
        height: '60px',
        alignItems: 'center',
        gridGap: gap,
        paddingRight: 4,
        position: 'relative',
        cursor: isLPA ? 'default' : 'pointer',
        overflow: 'hidden',
      }}
      onClick={editUser}
      onKeyDown={({ key }) => { if (key === 'Enter') { editUser(); } }}
      data-testid="user-row"
      role="gridcell"
      tabIndex="0"
    >
      <Box variant="rowItem" role="row" ml={5} sx={{ gridArea: 'name' }} title={user.fullName}>{user.fullName}</Box>
      <Box variant="rowItemAlt" role="row" sx={{ gridArea: 'email' }} title={user.email}>{user.email}</Box>
      <Box variant="rowItemAlt" role="row" sx={{ gridArea: 'nickname' }} title={user.nickname}>{user.nickname}</Box>
      <Box variant="rowItemAlt" role="row" sx={{ gridArea: 'login-name' }} title={user.loginName}>{user.loginName}</Box>
      <Box variant="rowItemAlt" role="row" sx={{ gridArea: 'last-updated' }} title={formattedDate}>{formattedDate}</Box>
    </Grid>
  );
}

UserRow.whyDidYouRender = true;

export default memo(UserRow);
