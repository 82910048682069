const BUTTON_HEIGHT = '40px';

export default {
  breakpoints: ['40em', '52em', '75em', '100em'],
  fontSizes: [
    '.75rem', '.875rem', '1rem', '1.25rem', '1.5rem', '2rem', '2.5rem',
  ],
  space: [
    0, '.25rem', '.5rem', '1rem', '1.5rem', '2rem', '2.5rem', '4rem', '8rem', '11.875rem',
  ],
  colors: {
    white: '#FCFCFD',
    lightGray: '#D6DAE6',
    grayLighter: '#DBDCE1',
    cloud: '#A7ACBE',
    cloudDark: '#6C7189',
    navyLighter: '#2E305F',
    orange: '#FB6500',
    orangeMid: '#EE722E',
    orangeLight: '#FCAC73',
    orangeDark: '#C1570A',
    navy: '#090C43',
    lighterNavy: '#1C2049',
    otherNavy: '#35376C',
    navyLight: '#1D1F52',
    navyGray: '#2D2F52',
    darkGray: '#675D55',
    kindaNavy: '#43457A',
    grayLight: '#C5C7CE',
    midGray: '#5D678E',
    transparentGray: 'rgba(66, 74, 98, 0.39)',
    transparentWhite: 'rgba(255, 255, 255, 0.39)',
    periwinkleLight: '#8BA3EA',
    electricBlue: '#005EF4',
    electricBlueHover: '#003FA3',
    red: '#DF2A31',
  },
  variants: {
    filterTitle: {
      color: 'grayLight',
      fontWeight: 700,
      fontSize: '16px',
      paddingLeft: '3',
    },
    filterItem: {
      color: 'grayLight',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '40px',
      borderLeft: '4px solid transparent',
      cursor: 'pointer',
      textDecoration: 'none',
    },
    filterCheckItem: {
      color: 'grayLight',
      fontSize: '14px',
      lineHeight: '20px',
      cursor: 'pointer',
    },
    filterItemActive: {
      color: 'orangeLight',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '40px',
      borderWidth: '0 0 0 4px',
      borderStyle: 'solid',
      borderColor: 'orangeLight',
      textDecoration: 'none',
    },
    filterHeader: {
      color: 'grayLight',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '20px',
      textTransform: 'uppercase',
    },
    rowHeader: {
      color: 'grayLight',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '20px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    rowItem: {
      color: 'white',
      fontSize: '16px',
      lineHeight: '24px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    rowItemAlt: {
      color: 'cloud',
      fontSize: '16px',
      lineHeight: '24px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    rowItemAction: {
      color: 'periwinkleLight',
      fontSize: '16px',
      lineHeight: '24px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    rowItemSmall: {
      color: 'white',
      fontSize: '12px',
      lineHeight: '16px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    rowItemAccount: {
      color: 'white',
      fontSize: '16px',
      lineHeight: '24px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      borderStyle: 'solid',
      borderColor: 'otherNavy',
      padding: 3,
      paddingLeft: 7,
    },
    rowItemHighlight: {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '24px',
      color: 'periwinkleLight',
    },
    rowItemTitle: {
      color: 'white',
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
    },
    rowItemError: {
      color: 'red',
    },
    cardName: {
      color: 'white',
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
    },
    navTitle: {
      color: 'white',
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  text: {
    heading: {
      color: 'white',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '36px',
      fontFamily: "'Roboto', sans-serif",
    },
  },
  buttons: {
    primary: {
      color: 'white',
      backgroundColor: 'orange',
      borderRadius: '20px',
      fontSize: 1,
      fontWeight: 700,
      cursor: 'pointer',
      textShadow: '1px 1px 1px #DB3B00,-1px -1px 1px #DB3B00,-1px 1px 1px #DB3B00,1px -1px 1px #DB3B00',
      height: BUTTON_HEIGHT,
      '&:hover': {
        color: 'white',
        backgroundColor: 'orangeDark',
      },
    },
    outlinedWhite: {
      color: 'white',
      backgroundColor: 'transparent',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'transparentWhite',
      borderRadius: '20px',
      fontSize: 1,
      fontWeight: 700,
      cursor: 'pointer',
      height: BUTTON_HEIGHT,
      '&:hover': {
        backgroundColor: 'otherNavy',
        color: 'white',
      },
    },
    disabled: {
      color: 'transparentWhite',
      backgroundColor: 'cloud',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'transparentWhite',
      borderRadius: '20px',
      fontSize: 1,
      fontWeight: 700,
      height: BUTTON_HEIGHT,
    },
    textDisabled: {
      color: 'midGray',
    },
  },
  forms: {
    label: {
      display: 'block',
      color: 'white',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      cursor: 'pointer',
    },
    checkbox: {
      color: 'orangeMid',
      '&:focus': {
        outline: 'none',
      },
    },
    searchInput: {
      color: 'white',
      fontSize: '14px',
      lineHeight: '20px',
      '&::placeholder': {
        color: 'lightGray',
      },
    },
    input: {
      color: 'white',
      fontSize: '14px',
      lineHeight: '20px',
      '&::placeholder': {
        color: 'grayLight',
      },
    },
    inputError: {
      borderColor: 'red',
      '&::placeholder': {
        color: 'grayLight',
      },
    },
    inputDisabled: {
      borderColor: 'cloudDark',
      '&::placeholder': {
        color: 'cloudDark',
      },
    },
    filterCheckedLabel: {
      color: 'white',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      cursor: 'pointer',
    },
    filterUncheckedLabel: {
      color: 'grayLight',
      fontSize: '14px',
      lineHeight: '20px',
      cursor: 'pointer',
    },
    select: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
  },
};
