import React, {
  useState, memo, useContext, useRef, useEffect,
} from 'react';
import {
  Box, Flex, Grid, Button,
} from 'theme-ui';
import axios from 'axios';
import FocusTrap from 'focus-trap-react';
import { ReactComponent as DeleteIcon } from '../assets/delete.svg';
import UserInput from './UserInput';
import Loader from './Loader';
import AnalyticsContext from './AnalyticsContext';
import useKeyDown from '../hooks/useKeyDown';
import { NETWORK_ERROR, NETWORK_TIMEOUT } from '../consts';
import { sortByFullName, cleanFields } from '../utils';
import ProfileImage from './ProfileImage';

const INITIAL_ERROR_STATE = {
  email: false,
  fullName: false,
  nickname: false,
  passwordSh: false,
  pictureUrl: false,
  loginName: false,
};

const EMPTY_USER = {
  loginName: '',
  email: '',
  fullName: '',
  nickname: '',
  passwordSh: '',
  pictureUrl: '',
};

function UserModal(props) {
  const {
    type, userInfo, token, accountId, setUsers, users, logoutOnError, setUserInfo,
    setModalType, setCurrentUser, currentUser, replaceProfileId, profiles,
  } = props;

  const userModalRef = useRef(false);
  const isEdit = type === 'edit';
  const isAdd = !isEdit;

  const INPUTS = [{
    name: 'loginName',
    label: 'Login Name',
    placeholder: 'login_name',
    required: isAdd,
    disabled: isEdit,
  }, {
    name: 'fullName',
    label: 'Full Name',
    placeholder: 'Full Name',
    required: true,
  }, {
    name: 'nickname',
    label: 'Nickname',
    placeholder: 'nickname',
    required: true,
  }, {
    name: 'passwordSh',
    label: 'New Password',
    placeholder: isAdd ? 'Leave blank to keep the current password' : '********',
    required: false,
    type: 'password',
  }, {
    name: 'email',
    label: 'Email Address',
    placeholder: 'user@yourdomain.com',
    required: true,
  }, {
    name: 'pictureUrl',
    label: 'Picture URL',
    placeholder: 'http://www.yourdomain.com/picture.jpg',
  }];

  const [isLoading, setIsLoading] = useState(false);
  const [userFields, setUserFields] = useState(userInfo || EMPTY_USER);
  const [errorMessages, setErrorMessages] = useState([]);
  const [fieldErrors, setFieldErrors] = useState(INITIAL_ERROR_STATE);
  const { sendEvent } = useContext(AnalyticsContext);

  const closeModal = (reason) => {
    // Set the user to null to close the modal
    setModalType(null);
    setUserInfo(null);

    // Send the metric after, to improve response time for user
    if (reason) {
      sendEvent({ context: 'User', eventName: `User ${type} modal closed by ${reason}` });
    }
  };

  useKeyDown('Escape', (e) => {
    e.preventDefault();
    closeModal('escape key');
  });

  const setFieldError = (field, value) => setFieldErrors(
    (f) => ({ ...f, [field]: value }),
  );

  const resetFieldErrors = () => setFieldErrors(INITIAL_ERROR_STATE);

  const handleInputChange = (field, value) => {
    // Update the input value state
    setUserFields((current) => ({ ...current, [field]: value }));
    // Clear the input field error
    setFieldError(field, false);
  };

  const validateDetails = (errors) => {
    // Store array of messages to render
    const errorsArray = [];

    // Render errors for all fields that fail validation
    errors.details.forEach((error) => {
      errorsArray.push(error.message);
      error.path.forEach((path) => setFieldError(path, true));
    });
    setErrorMessages(errorsArray);
  };

  const validateFields = (userData) => {
    // If there are fields that contain errors, highlight them
    userData?.fields.forEach((path) => setFieldError(path, true));
    setErrorMessages([userData.message]);
  };

  const validateMessage = (userData, message) => {
    // If there are fields that contain errors, highlight them
    userData?.fields.forEach((path) => setFieldError(path, true));
    // There was a generic error
    setErrorMessages([message]);
  };

  const handleUser = ({ data }) => {
    const {
      data: userData, status, errors, message,
    } = data;
    if (userModalRef.current) {
      if (status >= 200 && status < 300) {
        // Successful, so first update this user in the list of users
        if (isAdd) {
          setUsers([
            ...users,
            { ...userData, profiles: userData?.profileIds?.map(replaceProfileId) },
          ].sort(sortByFullName));
        } else {
          const updateCurrentUser = (user) => (user.id === userData.id ? userData : user);
          setUsers((u) => u.map(updateCurrentUser).sort(sortByFullName));
        }

        // If the current user is the user we are editing
        if (isEdit && currentUser.id === userData.id) {
          // then update the user with the new profile image
          setCurrentUser(userData);
        }

        // Successful, so we close the modal
        closeModal();

        sendEvent({ context: 'User', eventName: `User ${type} success`, value: userData.id });
      } else if (status >= 400 && status < 500) {
        if (isEdit && (userData?.internalCode === 1031 || userData?.internalCode === 204)) {
          // If the same password was used, set the error message
          setErrorMessages(['This password has already been used']);
        } else if (userData?.message) {
          // If there is feedback, display it
          setErrorMessages([userData.message]);
        } else if (message) {
          setErrorMessages([message]);
        }

        // For each field that has an error, we should set the error state of that field
        userData?.fields?.forEach((field) => {
          setFieldError(field, true);
        });

        // Unset the loading state, so the user can try again
        setIsLoading(false);
        sendEvent({ context: 'User', eventName: `User ${type} field error`, value: userData?.fields?.join(',') });
      } else {
        if (errors?.details?.length) {
          validateDetails(errors);
        } else if (userData?.message) {
          validateFields(userData);
        } else if (message) {
          validateMessage(userData, message);
        } else {
          // There was an unknown error
          setErrorMessages(['An unknown error occured']);
        }

        // Unset the loading state, so the user can try again
        setIsLoading(false);
        sendEvent({ context: 'User', eventName: `User ${type} failure`, value: status });
      }
    }
  };

  const handleUserError = (e) => {
    if (userModalRef.current) {
      if (e.message === NETWORK_ERROR) {
        sendEvent({ context: 'User', eventName: `User ${type} network failure` });
      } else if (e.message.includes('timeout of')) {
        sendEvent({ context: 'User', eventName: `User ${type} network timeout`, value: NETWORK_TIMEOUT });
      } else {
        sendEvent({ context: 'User', eventName: `User ${type} unknown error` });
      }
      setIsLoading(false);
      logoutOnError(e);
    }
  };

  const handleSubmit = async () => {
    // Set a loading state, so the user knows that they are waiting for the response
    setIsLoading(true);
    setErrorMessages([]);
    resetFieldErrors();

    const adminProfileId = isAdd && profiles?.find((item) => item.roleTypeId === 1)?.id;

    if (!!adminProfileId || isEdit) {
      await axios.post(
        `/api/user/${isAdd ? 'create' : 'edit'}`,
        {
          userInfo: cleanFields(userFields),
          accountId,
          adminProfileId,
        },
        {
          timeout: NETWORK_TIMEOUT,
          headers: {
            Authorization: token.access_token,
          },
        },
      ).then(handleUser).catch(handleUserError);
    }
  };

  // Use a ref to check if the the component is mounted
  useEffect(() => {
    userModalRef.current = true;
    return () => { userModalRef.current = false; };
  }, []);

  return (
    <Flex
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(0, 0, 0, 0.3)',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      aria-modal="true"
      role="dialog"
      aria-labelledby={`user-${type}-modal-header`}
    >
      <FocusTrap focusTrapOptions={{
        // By default, an error will be thrown if the focus trap contains no elements
        // in its tab order. With this option you can specify a fallback element to
        //  programmatically receive focus if no other tabbable elements are found.
        fallbackFocus: 'div',
      }}
      >
        <Grid sx={{
          gridGap: 0,
          gridTemplateRows: 'auto auto',
          gridTemplateAreas: '"header" "form"',
          borderRadius: '12px',
          overflow: 'hidden',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: 'lightGray',
        }}
        >
          <Box
            px={5}
            py={4}
            sx={{
              gridArea: 'form', backgroundColor: 'navyLighter', color: 'white', width: 384,
            }}
            as="form"
            autocomplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              mb={5}
            >
              <ProfileImage pictureUrl={userFields?.pictureUrl} size={96} />
            </Box>
            {INPUTS.map((item) => (
              <UserInput
                key={item.name}
                item={item}
                fieldErrors={fieldErrors}
                userFields={userFields}
                handleInputChange={handleInputChange}
              />
            ))}
            <Flex sx={{ justifyContent: 'flex-end' }} mt={4} mb={2}>
              <Button type="button" name="cancel" variant="outlinedWhite" onClick={() => closeModal('cancel')}>Cancel</Button>
              <Button type="submit" name="confirm" ml={3}>{isLoading ? <Loader size={18} opacity={1} /> : 'Confirm'}</Button>
            </Flex>
            {errorMessages.map((msg) => <Box data-testid="field-error" key={msg} mt={3} sx={{ color: 'orangeLight' }}>{msg}</Box>)}
          </Box>
          <Flex
            id={`user-${type}-modal-header`}
            role="heading"
            aria-level="2"
            px={5}
            py={4}
            sx={{
              gridArea: 'header',
              backgroundColor: 'navyLight',
              color: 'white',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {isAdd ? 'Administrator Details' : 'Edit User'}
            <Flex
              data-testid="close-button"
              sx={{ gridArea: 'header', cursor: 'pointer', color: 'cloudDark' }}
              onClick={() => closeModal('close')}
              onKeyDown={({ key }) => { if (key === 'Enter') { closeModal('close'); } }}
            >
              <DeleteIcon
                width={22}
                height={22}
                role="button"
                tabIndex="0"
                aria-label="close"
                style={{ padding: '5px' }} // NOTE: Added to increase the focus area
              />
            </Flex>
          </Flex>
        </Grid>
      </FocusTrap>
    </Flex>
  );
}

export default memo(UserModal);
