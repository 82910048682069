import React, { memo } from 'react';
import { Flex } from 'theme-ui';
import { ReactComponent as LoadingIcon } from '../assets/loading-spin.svg';

function Loader({ size = 30, opacity = 0.5 }) {
  return (
    <Flex
      sx={{
        flex: 1, alignItems: 'center', justifyContent: 'center', opacity,
      }}
      role="img"
      aria-label="loader"
    >
      <LoadingIcon height={size} width={size} />
    </Flex>
  );
}

Loader.whyDidYouRender = true;

export default memo(Loader);
