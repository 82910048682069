import React, {
  useRef, useState, memo, useContext, useCallback,
} from 'react';
import {
  Box, Flex, Grid, Input, Button,
} from 'theme-ui';
import Fuse from 'fuse.js';
import { ReactComponent as SearchIcon } from '../assets/search.svg';
import { ReactComponent as DeleteIcon } from '../assets/delete.svg';
import SearchOptionRow from './SearchOptionRow';
import AnalyticsContext from './AnalyticsContext';
import useClickOutside from '../hooks/useClickOutside';

const SEARCH_LIMIT = 5;

function isMatch(str1, str2) {
  return str1.toLowerCase() === str2.toLowerCase();
}

function Search({
  searchType, data, setSearch, searchKeys, placeholder, setModalType, isLPA,
}) {
  const searchRef = useRef();
  const inputRef = useRef();
  const [hasFocus, setHasFocus] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchOptions, setSearchOptions] = useState([]);
  const [activeOption, setActiveOption] = useState(0);

  const { sendEvent } = useContext(AnalyticsContext);

  const isSearchDisabled = !data || isLPA;
  const isAccount = searchType === 'Account';

  const handleClickOutside = (e) => {
    // If the the click occurs outside of the search container
    if (!searchRef.current.contains(e.target)) {
      // Set the focus to false
      setHasFocus(false);
    }
  };

  const resetSearch = useCallback(() => {
    // If the search options are already empty, no need to set again
    if (searchOptions.toString() !== '') { setSearchOptions([]); }

    // If the search value is already empty, no need to set again
    if (searchValue !== '') {
      setSearchValue('');
      setSearch('');
    }

    // If the active option is already null, no need to set again
    if (activeOption !== null) {
      setActiveOption(null);
    }

    // Maintain focus on input after resetting search
    inputRef.current.focus();
  }, [searchOptions, searchValue]);

  const handleChange = useCallback((e) => {
    // Reset the search if the user cleared the input field manually
    if (e.target.value === '') {
      resetSearch();
    }

    // Setup new search
    const fuse = data && new Fuse(data, {
      keys: searchKeys,
      threshold: 0.3,
      findAllMatches: true,
      includeMatches: true,
    });

    // Setup empty object to store matches, and so we can increment the index via kepyress
    const foundMatches = [];

    // Do not search until there are at least 2 characters
    if (fuse && e.target.value.length >= 2) {
      // Search for matches, and add the item and matches
      fuse.search(e.target.value, { limit: SEARCH_LIMIT }).forEach(({ item, matches }) => {
        foundMatches.push({ item, matches: matches.map(({ key, value }) => ({ key, value })) });
      });

      // Reset the active option, if it is set
      setActiveOption(null);

      // No need to update if nothing has changed
      if (foundMatches.toString() !== searchOptions.toString()) {
        setSearchOptions(foundMatches);
      }
    } else {
      // Don't show search options when there is only one letter in the search
      setSearchOptions([]);
    }

    // Finally set the search value
    setSearchValue(e.target.value.toLowerCase());
  }, [data, searchOptions, activeOption, searchKeys]);

  const incrementSearchIndex = useCallback(() => {
    if (searchOptions.length) {
      if (activeOption === null) {
        setActiveOption(0);
      } else {
        setActiveOption(
          (val) => (val >= searchOptions.length - 1 ? 0 : val + 1),
        );
      }
    }
  }, [searchOptions, activeOption]);

  const decrementSearchIndex = useCallback(() => {
    if (searchOptions.length) {
      if (activeOption === null) {
        setActiveOption(searchOptions.length - 1);
      } else {
        setActiveOption(
          (val) => (val <= 0 ? searchOptions.length - 1 : val - 1),
        );
      }
    }
  }, [searchOptions, activeOption]);

  const performSearch = (value, eventName) => {
    // This sets the string to search the accounts with
    setSearch(value);
    // This then hides the search dropdown, so the user can focus on their selections
    setHasFocus(false);
    // This also updates the search value, to give visual feedback on their search choice
    setSearchValue(value);
    // Reset the active option
    setActiveOption(null);
    // Log the metric
    sendEvent({
      context: `${searchType} search`,
      eventName,
      value,
      term: searchValue,
    });
  };

  const handleSearch = (title, subtitle1, subtitle2, eventName) => {
    // Find out what the user is trying to type and match with that
    try {
      const regex = new RegExp(searchValue, 'gi');
      const titleMatch = !!title.match(regex) || isMatch(searchValue, title);
      const subtitle1Match = !!subtitle1.match(regex) || isMatch(searchValue, subtitle1);
      const subtitle2Match = !!subtitle2.match(regex) || isMatch(searchValue, subtitle2);

      if (titleMatch) {
        performSearch(title, eventName);
      } else if (subtitle1Match) {
        performSearch(subtitle1, eventName);
      } else if (subtitle2Match) {
        performSearch(subtitle2, eventName);
      } else {
        performSearch(title, eventName);
      }
    } catch (e) {
      // If the regular expression search fails, then defult to search by title
      performSearch(title, eventName);
    }
  };

  const handleKeyDown = (e) => {
    const { key, target } = e;

    switch (key) {
      case 'Tab':
        setHasFocus(false);
        resetSearch();
        break;
      case 'Escape':
        resetSearch();
        break;
      case 'ArrowDown':
        e.preventDefault();
        incrementSearchIndex();
        break;
      case 'ArrowUp':
        e.preventDefault();
        decrementSearchIndex();
        break;
      case 'Enter':
        if (searchValue?.length > 1) {
          if (activeOption !== null) {
            // Get values from activeOption
            const {
              name, fullName, merchantId, region, loginName, email,
            } = searchOptions[activeOption].item;

            // Setup title and subtitle strings
            const title = (isAccount ? name : fullName) || '';
            const subtitle1 = (isAccount ? merchantId : email) || '';
            const subtitle2 = (isAccount ? region : loginName) || '';
            handleSearch(title, subtitle1, subtitle2, 'Search performed with Enter key');
          } else {
            performSearch(searchValue, 'Search performed with Enter key');
          }
          // Blur the input
          target.blur();
        }
        break;
      default:
        if (searchValue === '') { resetSearch(); }
        break;
    }
  };

  useClickOutside(handleClickOutside);

  return (
    <Flex sx={{ flexDirection: 'row', alignItems: 'center' }}>
      <Grid
        sx={{
          gridTemplateColumns: 'auto 1fr auto',
          gridTemplateAreas: '"icon input close"',
          gridGap: 0,
          width: [200, 280, 380, 530],
          transition: 'width 0.23s ease-in-out',
          height: 40,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: hasFocus ? 'white' : 'cloudDark',
          borderRadius: 20,
          alignItems: 'center',
          color: 'cloudDark',
          position: 'relative',
          opacity: data ? 1 : 0.4,
        }}
        pl={3}
        ref={searchRef}
        role="search"
      >
        <Flex sx={{ gridArea: 'icon', alignItems: 'center', color: hasFocus ? 'white' : 'cloudLight' }}>
          <SearchIcon width={16} height={16} aria-hidden="true" />
        </Flex>
        <Box sx={{ gridArea: 'input' }}>
          <Input
            ref={inputRef}
            aria-label="searchbox"
            variant="searchInput"
            type="text"
            placeholder={placeholder}
            value={searchValue || ''}
            disabled={!data} // disable the input field until the data has loaded
            onChange={handleChange}
            onFocus={() => {
              setActiveOption(null);
              setHasFocus(true);
            }}
            onKeyDown={handleKeyDown}
            pr={3}
            sx={{
              fontFamily: 'Roboto', /* override default font */
              width: '100%',
              height: 40,
              border: 'none',
              '&:focus': {
                outline: 'none', /* hide default outline */
              },
            }}
          />
        </Box>
        <Flex
          sx={{
            gridArea: 'close',
            alignItems: 'center',
            justifyContent: 'center',
            width: 40,
            height: 40,
            cursor: searchValue?.length ? 'pointer' : 'default',
          }}
          onClick={resetSearch}
          data-testid="clear-search"
        >
          {searchValue?.length ? (
            <DeleteIcon width={10} height={10} style={{ marginBottom: '3px' }} />
          ) : null}
        </Flex>
        {searchOptions.length && hasFocus ? (
          <Box
            py={2}
            sx={{
              backgroundColor: 'navyLighter',
              borderRadius: '6px',
              position: 'absolute',
              overflow: 'hidden',
              top: 47,
              left: '-1px',
              right: '-1px',
              zIndex: 1,
              boxShadow: '0px 4px 24px rgba(22, 23, 64, 0.54)',
            }}
          >
            {searchOptions.map((match, index) => (
              <SearchOptionRow
                index={index}
                item={match.item}
                key={match.item.id}
                searchType={searchType}
                searchValue={searchValue}
                isActive={activeOption === index}
                setActiveOption={setActiveOption}
                handleSearch={handleSearch}
              />
            ))}
          </Box>
        ) : null}
      </Grid>
      {setModalType && (
        <Button
          ml={4}
          disabled={isSearchDisabled}
          variant={isSearchDisabled ? 'disabled' : 'primary'}
          onClick={() => {
            setModalType('add');
            sendEvent({ context: 'User', eventName: 'User add clicked' });
          }}
          type="button"
          sx={{ flexShrink: 0 }}
        >
          + Add Administrator
        </Button>
      )}
    </Flex>
  );
}

Search.whyDidYouRender = true;

export default memo(Search);
