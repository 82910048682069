import './wdyr'; // Why-did-you-render?

import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import { ThemeProvider } from 'theme-ui';
import App from './components/App';
import theme from './theme';

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <Router basename={process.env.PUBLIC_URL}>
        <App />
      </Router>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root'),
);
