import React, { useEffect, useState, useContext } from 'react';
import {
  Button, Input, Label, Flex, Box,
} from 'theme-ui';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import backgroundImage from '../assets/login-background.jpg';
import { ReactComponent as PartnersLogo } from '../assets/partners-logo.svg';
import { encodeState } from '../utils';
import Loader from './Loader';
import { NETWORK_TIMEOUT, NOT_A_PARTNER, INVALID_REQUEST } from '../consts';
import AnalyticsContext from './AnalyticsContext';

function Login({
  setToken, token, accountId, setAccountId, logoutReason,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(logoutReason || null);
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get('code');
  const { sendEvent } = useContext(AnalyticsContext);

  const handleLogin = async () => {
    // NOTE: sending a BAM event requires a token, we cannot send pre-login events

    // Only proceed if we have an account ID, and we are not already waiting for it
    if (!isLoading && accountId) {
      // Set a loading state, so that the user knows that we are waiting on something to happen
      setIsLoading(true);
      setErrorMessage('');

      // Attempt to get the authURL from the API server
      try {
        const response = await axios.post(
          '/api/auth/login',
          { accountId, state: encodeState({ accountId }) },
          { timeout: NETWORK_TIMEOUT },
        );

        // Check that the authURL is correct, otherwise let the user try again
        if (typeof response.data.authUrl === 'string') {
          window.location.assign(response.data.authUrl);
        } else if (response.data.message === NOT_A_PARTNER) {
          setErrorMessage('Account is not a partner account');
          setIsLoading(false);
        } else if (response.data.message === INVALID_REQUEST) {
          setErrorMessage('Invalid request');
          setIsLoading(false);
        } else {
          setErrorMessage('Please check your Account ID and try again');
          setIsLoading(false);
        }
      } catch (e) {
        setErrorMessage(e.message);
        setIsLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    // Clear error message when the input field changes
    setErrorMessage('');

    // Set the account ID, but trim extra spaces from the edge
    setAccountId(e.target.value.trim());
  };

  useEffect(() => {
    const fetchToken = async () => {
      if (code && accountId) {
        try {
          const { data } = await axios(
            `/api/auth/code?code=${code}&accountId=${accountId}`,
            { timeout: NETWORK_TIMEOUT },
          );

          if (data?.token) {
            setToken(data.token);
            sendEvent({ context: 'Login', eventName: 'Login success', value: accountId });
          } else {
            sendEvent({ context: 'Login', eventName: 'Login code invalid', value: accountId });
            // An error occurred, so send the user back to the homepage
            window.location.assign('/');
          }
        } catch (e) {
          sendEvent({ context: 'Login', eventName: 'Login code failure', value: accountId });
          // An error occurred, so send the user back to the homepage
          window.location.assign('/');
        }
      }
    };
    fetchToken();
  }, [code, accountId]);

  // If there is a token, or if there is a code but no accountId, then redirect to the homepage
  if (token || (code && !accountId)) {
    return <Redirect to="/" />;
  }

  // If there is a code, this means that we are waiting to validate the login, so we return nothing
  if (code) {
    return null;
  }

  // Otherwise, we present the login screen to the user
  return (
    <Flex
      sx={{
        height: '100%',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Flex
        px="5rem"
        pt={7}
        pb={6}
        sx={{
          width: ['100%', 'auto'],
          margin: [4, 0],
          borderRadius: '12px',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          backgroundColor: 'navyLight',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <PartnersLogo width="100%" role="img" aria-label="Liveperson Partners Logo" />
        <title>Liveperson Partners</title>
        <Label
          mt={6}
          mb={2}
          sx={{
            color: 'grayLight',
            fontWeight: 700,
            fontSize: '13px',
            lineHeight: '18px',
            width: ['100%', 288],
          }}
          htmlFor="account-number-label"
        >
          Sign in with Conversational Cloud
        </Label>
        <Input
          px={3}
          sx={{
            width: ['100%', 288],
            color: 'white',
            height: '38px',
            fontSize: '12px',
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
            border: '1px solid rgba(255, 255, 255, 0.22)',
            '&:focus': {
              border: '1px solid #FF6900',
              outline: 'none',
            },
          }}
          aria-required="true"
          id="account-number-label"
          name="accountId"
          placeholder="Enter your account number"
          value={accountId}
          onChange={handleChange}
          onKeyDown={(e) => {
            // Hitting Enter should attempt to login
            if (e.key === 'Enter') {
              handleLogin();
            }
          }}
        />
        <Button
          mt={5}
          sx={{ width: '132px', height: '40px' }}
          onClick={handleLogin}
          variant={accountId ? 'primary' : 'disabled'}
          aria-disabled={!accountId}
        >
          {isLoading ? <Loader size={18} opacity={1} /> : 'Continue'}
        </Button>
        {errorMessage && <Box aria-label="error" mt={3} sx={{ color: 'orangeLight', fontSize: 1 }} aria-describedby="account-number-label" role="alert">{errorMessage}</Box>}
      </Flex>
    </Flex>
  );
}

Login.whyDidYouRender = true;

export default Login;
