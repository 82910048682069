import React, { memo } from 'react';
import { Flex, Box } from 'theme-ui';
import { ReactComponent as NoResults } from '../assets/no-results.svg';

function EmptyState({ search, errorMessage }) {
  return (
    <Flex sx={{
      marginTop: 8,
      alignItems: 'center',
      flexDirection: 'column',
      color: 'white',
      flex: 1,
    }}
    >
      <NoResults width={106} height={105} role="img" />
      <Box
        mt={5}
        sx={{
          color: 'white', fontWeight: 700, fontSize: 4, lineHeight: '28px',
        }}
      >
        {errorMessage || 'No Results Found'}
      </Box>
      {search ? <Box mt={3} sx={{ color: 'cloud', fontSize: 1, lineHeight: '20px' }}>{`We couldn’t find any results for “${search}”`}</Box> : null}
      {search ? <Box sx={{ color: 'cloud', fontSize: 1, lineHeight: '20px' }}>Check your spelling or try broadening your search</Box> : null}
    </Flex>
  );
}

EmptyState.whyDidYouRender = true;

export default memo(EmptyState);
