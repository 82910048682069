import React, { memo } from 'react';
import {
  Box, Flex, Text,
} from 'theme-ui';
import Highlighter from 'react-highlight-words';
import { ReactComponent as AccountIcon } from '../assets/profile-image.svg';

function Spacer() {
  return <Text as="span" mx={2}>{' • '}</Text>;
}

function SearchOptionRow({
  item, index, searchValue, handleSearch, isActive, setActiveOption, searchType,
}) {
  const isAccount = searchType === 'Account';
  const {
    fullName, email, name, merchantId, loginName, region,
  } = item;
  // Setup background colour for active/hover state
  const backgroundColor = isActive ? '#383A6F' : '#2E305F';
  // Setup linear gradient so that the overflowed text fades out instead of cutting off
  const linearGradient = `linear-gradient(to right, transparent, ${backgroundColor} 80%)`;
  // Setup highlight styles for search matches
  const unhighlightStyle = { color: 'lightGray', fontSize: 13, flexShrink: 0 };
  const highlightStyle = { ...unhighlightStyle, fontWeight: 600 };

  // Setup title and subtitle strings
  const title = (isAccount ? name : fullName) || '';
  const subtitle1 = (isAccount ? merchantId : email) || '';
  const subtitle2 = (isAccount ? region : loginName) || '';

  return (
    <Flex
      data-testid="search-option-row"
      px={3}
      sx={{
        position: 'relative',
        alignItems: 'center',
        height: 48,
        cursor: 'pointer',
        backgroundColor,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
      onClick={() => handleSearch(title, subtitle1, subtitle2, 'Search performed with click')}
      onMouseOver={() => setActiveOption(index)}
    >
      <Box sx={{
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        width: 50,
        backgroundImage: linearGradient,
        background: linearGradient,
      }}
      />
      {loginName && (
        <Flex mr={2} sx={{ alignItems: 'center', flexShrink: 0 }}>
          <AccountIcon width={28} height={28} role="img" aria-hidden="true" />
        </Flex>
      )}
      <Flex sx={{ alignItems: 'center' }}>
        <Highlighter
          highlightStyle={{ ...highlightStyle, fontSize: 16 }}
          unhighlightStyle={{ ...unhighlightStyle, fontSize: 16 }}
          searchWords={[searchValue]}
          autoEscape
          textToHighlight={title}
          highlightTag="span"
        />
        <Spacer />
        <Highlighter
          highlightStyle={highlightStyle}
          unhighlightStyle={unhighlightStyle}
          searchWords={[searchValue]}
          autoEscape
          textToHighlight={subtitle1}
          highlightTag="span"
        />
        <Spacer />
        <Highlighter
          highlightStyle={highlightStyle}
          unhighlightStyle={unhighlightStyle}
          searchWords={[searchValue]}
          autoEscape
          textToHighlight={subtitle2}
          highlightTag="span"
        />
      </Flex>
    </Flex>
  );
}

SearchOptionRow.whyDidYouRender = true;

export default memo(SearchOptionRow);
