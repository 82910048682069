import jwt from 'jsonwebtoken';

// Encode the state object into a string so that it can be safely handled by sentinel
function encodeState(stateObj) {
  const safeString = btoa(JSON.stringify(stateObj));
  // Remove equals signs from the encoded string, because sentinel rejects it otherwise
  return safeString.replace(/=/g, '');
}

// Decode the state object from the string that was returned from the SSO process
function decodeState(stateStr) {
  try {
    return JSON.parse(atob(stateStr));
  } catch (e) {
    return {};
  }
}

// NOTE: This is used as the date offset to calculate a 'recent' date
const ONE_MONTH = 31 * 24 * 60 * 60 * 1000;

function filterByType(activeFilter, item) {
  return (activeFilter === 'all')
    || (activeFilter === 'recent' && new Date(item.createdDate).getTime() > Date.now() - ONE_MONTH)
    || (activeFilter === 'starred' && item.isStarred);
}

const filterByItem = (data, type) => [...data.reduce(
  (set, account) => set.add(account[type]),
  new Set(),
)];

function getTotal(total, item, profileId) {
  return item.profileIds?.find(
    (id) => id === profileId,
  ) ? total + 1 : total;
}

function countRoles(data, profileId) {
  // check data for how many ids are equal to the current profile id
  return data.reduce((total, item) => getTotal(total, item, profileId), 0);
}

function countRegions(data, region) {
  // check data for how many regions are equal to the current region
  return data.reduce((total, item) => (item.region === region ? total + 1 : total), 0);
}

function findByRegionFilter(regionFilters, account) {
  const check = (acc, filter) => acc || filter.checked;
  const hasRegionFilter = regionFilters?.reduce(check, false);

  const filter = (a, b) => {
    const active = b.checked && account.region === b.name;
    return active || a;
  };

  // If there are filters active, return the filtered item
  if (hasRegionFilter) {
    return regionFilters.reduce(filter, false);
  }

  // If there are no filters active, return the account
  return account;
}

function findUsersByFilter(filter, user) {
  if (filter === 'all') { return true; }
  return user.profiles?.find((f) => f.name === filter);
}

function parseFilter(str) {
  switch (str) {
    case 'recent':
      return 'Recently created';
    case 'starred':
      return 'Starred';
    default:
      return 'All';
  }
}

const sortByName = (a, b) => (a.name > b.name || !a.name ? 1 : -1);

const sortByFullName = (a, b) => (
  a.fullName?.toLowerCase() > b.fullName?.toLowerCase() || !a.fullName ? 1 : -1
);

const getUserId = (token) => {
  try {
    return JSON.parse(atob(token.id_token.split('.')[1])).sub;
  } catch (e) {
    return null;
  }
};

function checkTokenForLPA(token) {
  return !!jwt.decode(token?.id_token)?.is_lpa;
}

// Used to remove profiles and other unwanted properties from a user object
const cleanFields = ({
  userTypeId, isApiUser, resetMfaSecret, pid, dateUpdated, deleted, maxChats,
  skillIds, id, maxAsyncChats, memberOf, managerOf, loginName, email,
  fullName, nickname, passwordSh, pictureUrl, isEnabled, profileIds,
}) => ({
  userTypeId,
  isApiUser,
  resetMfaSecret,
  pid,
  dateUpdated,
  deleted,
  maxChats,
  skillIds,
  id,
  maxAsyncChats,
  memberOf,
  managerOf,
  loginName,
  email,
  fullName,
  nickname,
  passwordSh,
  pictureUrl,
  isEnabled,
  profileIds,
});

export {
  decodeState,
  encodeState,
  filterByType,
  filterByItem,
  countRoles,
  countRegions,
  findByRegionFilter,
  findUsersByFilter,
  parseFilter,
  sortByName,
  sortByFullName,
  ONE_MONTH,
  getUserId,
  checkTokenForLPA,
  cleanFields,
};
