import React, { memo, useContext } from 'react';
import { Box, Label, Flex } from 'theme-ui';
import Checkbox from './Checkbox';
import {
  parseFilter, countRegions, ONE_MONTH,
} from '../utils';
import AnalyticsContext from './AnalyticsContext';

function FilterItem({
  label, isActive, handleClick,
}) {
  return (
    <li>
      <Box
        data-testid={isActive ? 'filter-item-active' : 'filter-item'}
        variant={isActive ? 'filterItemActive' : 'filterItem'}
        onClick={handleClick}
        aria-current={!!isActive}
        pl={3}
        mb={3}
        as="a"
        href="#"
      >
        {label}
      </Box>
    </li>
  );
}

function FilterHeader({ label, id }) {
  return <Box role="heading" id={id} aria-level="2" variant="filterHeader" pl={3} pt={7} mb={3}>{label}</Box>;
}

function FilterCheckItem({
  label, checked, setFilter, name,
}) {
  return (
    <Label
      variant={checked ? 'filterCheckedLabel' : 'filterUncheckedLabel'}
      pl={3}
      mb={3}
      sx={{ alignItems: 'center' }}
    >
      <Checkbox
        checked={checked}
        onChange={(e) => setFilter(e.target.checked)}
        name={name}
      />
      <Box id={name}>{label}</Box>
    </Label>
  );
}

function AccountFilter({
  regionFilters, setRegionFilters, setActiveFilter, accountData, filteredData, activeFilter,
}) {
  const { sendEvent } = useContext(AnalyticsContext);
  const countRecent = accountData?.filter(
    (d) => new Date(d.createdDate).getTime() > Date.now() - ONE_MONTH,
  ).length;
  const countStarred = accountData?.filter((d) => d.isStarred).length;

  const filterMap = [
    { name: 'all', count: accountData?.length },
    { name: 'recent', count: countRecent },
    { name: 'starred', count: countStarred },
  ];

  const handleClick = (item) => {
    setActiveFilter(item.name);
    sendEvent({
      context: 'Filtering',
      eventName: 'Account filtered',
      value: item.name,
    });
  };

  return (
    <Flex
      py={7}
      sx={{
        flexDirection: 'column',
        gridArea: 'filter',
        overflow: 'auto',
        backgroundColor: 'navyLight',
        borderColor: 'otherNavy',
        borderStyle: 'solid',
        borderWidth: '0 1px 0 0',
      }}
      role="navigation"
      aria-labelledby="manage-my-account-filter-role"
    >
      {accountData && (
        <>
          <Box variant="filterTitle" role="heading" id="account-filter-by-role" aria-level="2">View By</Box>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {filterMap.map((item) => (
              <FilterItem
                key={item?.name}
                label={`${parseFilter(item.name)} (${item.count})`}
                isActive={activeFilter === item.name}
                handleClick={() => handleClick(item)}
              />
            ))}
          </ul>
        </>
      )}
      {regionFilters.length ? <FilterHeader label="Region" id="region-type" /> : null}
      <Box role="group" aria-labelledby="region-type">
        {regionFilters.map((item) => (
          <FilterCheckItem
            key={item.name}
            data-testid="filter-checkbox"
            label={`${item.name || 'No Region'} (${countRegions(filteredData, item.name)})`}
            checked={item.checked}
            setFilter={(val) => {
              setRegionFilters(regionFilters.map(
                (region) => (region.name === item.name ? { ...region, checked: val } : region),
              ));
              sendEvent({
                context: 'Filtering',
                eventName: `Account ${val ? '' : 'un'}filtered by region`,
                value: item.name || 'No Region',
              });
            }}
            type="region"
            name={(item.name || 'No Region').split(' ').join('-').toLowerCase()}
          />
        ))}
      </Box>
    </Flex>
  );
}

AccountFilter.whyDidYouRender = true;

export default memo(AccountFilter);
