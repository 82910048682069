import React, { memo, useContext } from 'react';
import { Avatar } from 'theme-ui';
import DefaultUser from '../assets/avatar.png';
import AnalyticsContext from './AnalyticsContext';

function ProfileImage({ pictureUrl, size, hasPointer }) {
  const { sendEvent } = useContext(AnalyticsContext);

  // Tell jest to ignore this due to onError being difficult to test
  /* istanbul ignore next */
  const handleError = (e) => {
    e.target.src = DefaultUser;
    sendEvent({ context: 'ProfileImage', eventName: 'Profile image failed to load', value: pictureUrl });
  };

  return (
    <Avatar
      src={pictureUrl || DefaultUser}
      alt=""
      style={{
        cursor: hasPointer ? 'pointer' : 'default',
        borderRadius: `${(size / 2)}px`,
        height: `${size}px`,
        width: `${size}px`,
        backgroundSize: `${size}px`,
        objectFit: 'cover',
      }}
      onError={handleError}
    />
  );
}

ProfileImage.whyDidYouRender = true;

export default memo(ProfileImage);
