import React from 'react';
import { Box, Grid, Text } from 'theme-ui';
import AccountRow from './AccountRow';
import UserRow from './UserRow';
import { parseFilter, findByRegionFilter } from '../utils';
import Loader from './Loader';
import EmptyState from './EmptyState';

function TableHeader({
  tableHeaders, columns, isUser, gap,
}) {
  const gridAreas = tableHeaders.map(({ gridArea }) => gridArea).join(' ');
  const gridAreasString = `'${gridAreas}'`;

  return (
    <Grid
      sx={{
        backgroundColor: 'navyLighter',
        height: '48px',
        gridTemplateAreas: gridAreasString,
        gridTemplateColumns: columns,
        alignItems: 'center',
        gridGap: gap,
        paddingRight: 4,
      }}
      role="rowheader"
    >
      {tableHeaders && tableHeaders.map(({ gridArea, name, timezone }, index) => (
        <Box
          key={`${gridArea} - header`}
          variant="rowHeader"
          ml={isUser && index === 0 ? 5 : 0}
          sx={{ gridArea }}
          aria-label={name}
          title={timezone ? `${name} ${timezone}` : name}
          role="row"
          tabIndex="0"
        >
          {name}
          {timezone && <Text ml={2} sx={{ opacity: 0.6, fontWeight: 400 }}>{timezone}</Text>}
        </Box>
      ))}
    </Grid>
  );
}

const gap = [2, 3, 5];

function Table({
  data, columns, tableHeaders, search, activeFilter, isLoading, isLPA, accountId,
  isUser, setUserInfo, updateStar, errorMessage, regionFilters, setModalType,
}) {
  const filterAccounts = (item) => findByRegionFilter(regionFilters, item);
  const filteredData = isUser ? data : data?.filter(filterAccounts);
  const hasSearch = search?.length > 0 && !isLoading;
  const hasResults = data?.length > 0;

  return (
    <Grid
      sx={{
        gridArea: 'table',
        backgroundColor: 'navyLight',
        gridGap: 0,
        gridTemplateRows: hasSearch ? '48px auto 1fr' : '48px auto',
        overflow: 'hidden',
        flex: 1,
      }}
      role="treegrid"
      aria-labelledby="main-page-title-id"
    >
      <TableHeader
        tableHeaders={tableHeaders}
        columns={columns}
        isUser={isUser}
        gap={gap}
      />
      {hasSearch ? (
        <Box data-testid="search-results" sx={{ color: 'cloudDark' }} px={5} py={5}>
          {`${filteredData.length} result${filteredData.length > 1 ? 's' : ''} for `}
          <Box as="span" sx={{ color: 'grayLight', fontWeight: 700 }}>{`"${search}"`}</Box>
          {' in '}
          <Box as="span" sx={{ color: 'grayLight', fontWeight: 700, textTransform: 'capitalize' }}>
            {parseFilter(activeFilter)}
          </Box>
        </Box>
      ) : null}
      <Box sx={{ overflow: 'auto', display: hasResults ? 'block' : 'flex' }}>
        {hasResults ? filteredData.map((item) => {
          if (isUser) {
            return (
              <UserRow
                user={item}
                columns={columns}
                gap={gap}
                key={item.id}
                setUserInfo={setUserInfo}
                setModalType={setModalType}
                isLPA={isLPA}
              />
            );
          }
          return (
            <AccountRow
              account={item}
              accountId={accountId}
              columns={columns}
              gap={gap}
              key={item.id}
              updateStar={updateStar}
              isLPA={isLPA}
            />
          );
        }) : null}
        {isLoading ? <Loader /> : null}
        {!hasResults && !isLoading && <EmptyState search={search} errorMessage={errorMessage} />}
      </Box>
    </Grid>
  );
}

Table.whyDidYouRender = true;

export default Table;
