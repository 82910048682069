import { useEffect } from 'react';

export default function useKeyDown(key, action) {
  useEffect(() => {
    function onKeyDown(e) {
      if (e.key === key) action(e);
    }
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, []);
}
