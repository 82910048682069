import React, {
  useEffect, useState, memo, useRef, useContext,
} from 'react';
import { Heading, Flex, Grid } from 'theme-ui';
import Fuse from 'fuse.js';
import axios from 'axios';
import Table from './Table';
import Search from './Search';
import { checkTokenForLPA, findUsersByFilter, sortByFullName } from '../utils';
import UserModal from './UserModal';
import AnalyticsContext from './AnalyticsContext';
import { NETWORK_TIMEOUT } from '../consts';

const tableHeaders = [
  { gridArea: 'hd-name', name: 'Name' },
  { gridArea: 'hd-email', name: 'Email' },
  { gridArea: 'hd-nickname', name: 'Nickname' },
  { gridArea: 'hd-login-name', name: 'Login Name' },
  { gridArea: 'hd-last-updated', name: 'Last Updated', timezone: '(UTC -7:00)' },
];

function User({
  accountId, token, logoutOnError, setCurrentUser, currentUser,
}) {
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [profiles, setProfiles] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const { sendEvent } = useContext(AnalyticsContext);
  const userRef = useRef(false);
  const isLPA = checkTokenForLPA(token);
  const activeFilter = 'all';

  const replaceProfileId = (id) => profiles.find((x) => x.id === id);

  const handleProfiles = ({ data }) => {
    if (userRef.current) {
      setProfiles(data);
      sendEvent({ context: 'User', eventName: 'Profiles loaded', value: data.length });
    }
  };

  const handleProfilesError = (e) => {
    if (userRef.current) {
      setProfiles([]);
      if (e.message.includes('timeout of')) {
        setErrorMessage('A network timeout occurred');
        sendEvent({ context: 'User', eventName: 'Profiles timed out' });
      } else {
        sendEvent({ context: 'User', eventName: 'Profiles not loaded' });
      }
      logoutOnError(e);
    }
  };

  const updateUser = (userToUpdate) => {
    if (userToUpdate.profileIds && !isLPA) {
      return ({ ...userToUpdate, profiles: userToUpdate.profileIds.map(replaceProfileId) });
    }
    return ({ ...userToUpdate, profiles: [], profileIds: [] });
  };

  const handleUsersData = ({ data }) => {
    // NOTE: The check for being mounted works, but it seems almost impossible to test
    /* istanbul ignore next */
    if (userRef.current) {
      setUsers(data.map(updateUser).sort(sortByFullName));
      sendEvent({ context: 'User', eventName: 'Users loaded', value: data.length });
    }
  };

  const handleUsersError = (e) => {
    // NOTE: The check for being mounted works, but it seems almost impossible to test
    /* istanbul ignore next */
    if (userRef.current) {
      setUsers([]);
      if (e.message.includes('timeout of')) {
        setErrorMessage('A network timeout occurred');
        sendEvent({ context: 'User', eventName: 'Users timed out' });
      } else {
        sendEvent({ context: 'User', eventName: 'Users not loaded' });
      }
      logoutOnError(e);
    }
  };

  async function fetchUserData() {
    const usersUrl = `/api/users/admin?accountId=${accountId}`;
    await axios(
      usersUrl,
      {
        timeout: NETWORK_TIMEOUT,
        headers: {
          Authorization: token.access_token,
        },
      },
    ).then(handleUsersData).catch(handleUsersError);
  }

  useEffect(() => {
    // Fetch the user data on login
    if (profiles && token && accountId) {
      fetchUserData();
    }
  }, [accountId, profiles, token]);

  useEffect(() => {
    async function fetchUserProfiles() {
      const profilesUrl = `/api/profiles?accountId=${accountId}`;
      await axios(
        profilesUrl,
        {
          timeout: NETWORK_TIMEOUT,
          headers: {
            Authorization: token.access_token,
          },
        },
      ).then(handleProfiles).catch(handleProfilesError);
    }

    // Fetch the user profiles when the account id or auth token changes
    if (accountId && token) {
      fetchUserProfiles();
    }
  }, [accountId, token]);

  // Use a ref to check if the the component is mounted
  useEffect(() => {
    userRef.current = true;
    return () => { userRef.current = false; };
  }, []);

  const typeFilter = (item) => findUsersByFilter(activeFilter, item);
  const filteredData = users?.filter(typeFilter) || [];

  // Use Fuse.js to fuzzy search the filtered data
  const fuse = new Fuse(filteredData, {
    keys: ['fullName', 'email', 'loginName', 'nickname'],
    findAllMatches: true,
    threshold: 0.3,
  });

  const data = search?.length ? fuse.search(search).map((d) => d.item) : filteredData;

  return (
    <Grid
      px={4}
      pt={4}
      sx={{
        height: '100%', gridTemplateRows: 'auto 1fr', gridGap: 0, position: 'relative',
      }}
      role="main"
      id="main-content"
    >
      <Flex mb={6} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Heading as="h1" ml={4} id="user-overview-title">User Overview</Heading>
        <Search
          searchType="User"
          data={filteredData}
          search={search}
          setSearch={setSearch}
          searchKeys={['fullName', 'email', 'loginName', 'nickname']}
          placeholder="Search users"
          setModalType={setModalType}
          isLPA={isLPA}
        />
      </Flex>
      <Flex sx={{
        overflow: 'hidden',
        borderRadius: '8px 8px 0 0',
      }}
      >
        <Table
          data={data}
          activeFilter={activeFilter}
          search={search}
          columns="2fr 4fr 2fr 2fr 3fr"
          tableHeaders={tableHeaders}
          isUser
          userInfo={userInfo}
          setModalType={setModalType}
          setUserInfo={setUserInfo}
          errorMessage={errorMessage}
          isLoading={users === null}
          isLPA={isLPA}
        />
        {modalType && (
          <UserModal
            type={modalType}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            accountId={accountId}
            token={token}
            users={users}
            setUsers={setUsers}
            logoutOnError={logoutOnError}
            profiles={profiles}
            replaceProfileId={replaceProfileId}
            setCurrentUser={setCurrentUser}
            currentUser={currentUser}
            setModalType={setModalType}
          />
        )}
      </Flex>
    </Grid>
  );
}

User.whyDidYouRender = true;

export default memo(User);
